<template>
  <div class="content">
    <div class="banner">
        <div class="hint">
            <div class="logo">
              <div class="gradual-1">
                  <div class="gradual-2">
                    <img src="@/assets/jcw/logo.jpg">
                  </div>
              </div>
            </div>
            <div class="title">道路冰雪预警监测系统</div>
        </div>
    </div>
    <div class="info">
      <div class="inner">
          <van-cell title="当前项目名称" :value="projectName || '暂无'" />
          <van-cell title="版本号" value="v1.0.0" @click="version"/>
      </div>
    </div>
    
    <tabBar />
  </div>
</template>
<script>
import tabBar from '@/components/rxroad/tabBar.vue'
export default {
  components: {
    tabBar
  },
  data() {
    return {
      projectName:null
    }
  },
  mounted(){
    this.projectName = window.sessionStorage.getItem("projectName");
  },
  methods:{
    hint(){
      this.$toast('敬请期待！');
    },
    version(){
      this.$toast('当前是最新版本');
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  height: 12rem;
  background:linear-gradient(#99e6df 0%, #23b593 85%);
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  text-align: center;
  .hint{
    color: white;font-size: 1rem;padding-top: 10%;
    .title{padding-top: 2%;font-weight: bold;}
    .logo{
        margin: 0 auto;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        line-height: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(#66ddf1,rgba(255, 255, 255, 0.2));
        .gradual-1{
          margin: 0 auto;
          width: 95px;
          height: 95px;
          border-radius: 50%;
          line-height: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          background: radial-gradient(#66ddf1,rgba(255, 255, 255, 0.5));
        }
        .gradual-2{
          margin: 0 auto;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          line-height: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          background: radial-gradient(#66ddf1,rgba(255, 255, 255, 0.8));
        }
        img{
          width:65px;height: 65px;border-radius: 50%;margin: 2%;
        }
    } 
  }
}
.info{
  padding:0 4%;text-align: left;
  .inner{
    padding:0 2%;background-color: white;border-radius:10px;
    border: 1px solid #e6e6e6;
    box-shadow: 1px 1px #f0f0f0;
    .van-cell{padding: 16px}
    .van-cell__title{font-weight: bold;color: #363636;}
    }
}
</style>